import React, { Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingView from '../components/navigation/LoadingView';
import RootContainer from '../RootContainer';
import { getUserHasPermissionToView } from '../lib/userFunctions';
import SignOutContainer from '../components/logout/SignOutContainer';

// Enables lazy loading, each route (container) is splitted to its own chunk.
// Faster initial loads since only code required by the view is loaded.
const AsyncHOC = (Component) => {
  return props => (
    <Suspense fallback={<LoadingView />}>
      <Component {...props} />
    </Suspense>
  );
};

const AsyncExampleContainer = React.lazy(() => import('../components/ExampleContainer'));
const AsyncHomeContainer = React.lazy(() => import('../components/home/HomeContainer'));
const AsyncInspectionLinesContainer = React.lazy(() => import('../components/lines/InspectionLinesContainer'));
const AsyncInspectionLineContainer = React.lazy(() => import('../components/lines/InspectionLineContainer'));
const AsyncDefaultLinesContainer = React.lazy(() => import('../components/defaultLines/DefaultLinesContainer'));
const AsyncMyInfoContainer = React.lazy(() => import('../components/myInfo/MyInfoContainer'));
const AsyncWorkQueueContainer = React.lazy(() => import('../components/workQueue/WorkQueueContainer'));
const AsyncDevicesContainer = React.lazy(() => import('../components/devices/DevicesContainer'));
const AsyncDeviceContainer = React.lazy(() => import('../components/devices/DeviceContainer'));
const AsyncInspectionContainer = React.lazy(() => import('../components/inspection/InspectionContainer'));
const AsyncInspectionHistoryContainer = React.lazy(() => import('../components/inspectionHistory/InspectionHistoryContainer'));
const AsyncJobContainer = React.lazy(() => import('../components/inspectionHistory/JobContainer'));
const AsyncAtjPrintsContainer = React.lazy(() => import('../components/atjPrints/AtjPrintsContainer'));
const AsyncChainsContainer = React.lazy(() => import('../components/chainManagement/ChainsContainer'));
const AsyncChainContainer = React.lazy(() => import('../components/chainManagement/ChainContainer'));
const AsyncReportsContainer = React.lazy(() => import('../components/reports/ReportsContainer'));
const AsyncCopyDataContainer = React.lazy(() => import('../components/copyData/CopyDataContainer'));
const AsyncSurveilanceContainer = React.lazy(() => import('../components/surveillance/SurveillanceContainer'));
const AsyncSurveilanceDetailsContainer = React.lazy(() => import('../components/surveillance/DetailsContainer'));
const AsyncConditionInspectionJobTypesContainer = React.lazy(() => import('../components/conditionInspection/ConditionInspectionJobTypesContainer'));
const AsyncConditionInspectionJobTypeContainer = React.lazy(() => import('../components/conditionInspection/ConditionInspectionJobTypeContainer'));
const AsyncFaultReportContainer = React.lazy(() => import('../components/faultReport/FaultReportContainer'));
const AsyncHaynesInfoContainer = React.lazy(() => import('../components/haynesInfo/HaynesInfoContainer'));

function PrivateRoute({ component: Component, render, ...rest }) {
  const user = useSelector(state => state.auth.user);
  const pathParts = rest.path.split('/');
  const routeName = pathParts.length > 1 ? pathParts[1] : '';
  const canViewRoute = routeName === 'home' || getUserHasPermissionToView(user, routeName);

  const handleRender = () => Component ? <Component {...rest} /> : render();

  return (
    <Route
      {...rest}
      render={() => {
        return canViewRoute ? handleRender() : null;
      }}
    />
  );
}

function Routes() {
  const { pathname } = useLocation();
  return (
    <Switch>
      {/* Remove trailing slashes from URL: */}
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      {/* Web component views: */}
      <PrivateRoute exact path='/vehicleGroups/:id' component={RootContainer} />
      <PrivateRoute exact path='/vehicleGroups' component={RootContainer} />
      <PrivateRoute exact path='/jobTypes/:id' component={RootContainer} />
      <PrivateRoute exact path='/jobTypes' component={RootContainer} />
      <PrivateRoute exact path='/chain' component={RootContainer} />
      <PrivateRoute exact path='/sites/:id' component={RootContainer} />
      <PrivateRoute exact path='/sites' component={RootContainer} />
      <PrivateRoute exact path='/siteGroups/:id' component={RootContainer} />
      {/* <PrivateRoute exact path='/siteGroups' component={RootContainer} /> */}
      <PrivateRoute exact path='/users/:id' component={RootContainer} />
      <PrivateRoute exact path='/users' component={RootContainer} />
      {/* React views: */}
      <PrivateRoute exact path='/home' component={AsyncHOC(AsyncHomeContainer)} />
      <Route exact path='/logout' component={SignOutContainer} />
      <PrivateRoute exact path='/inspectionHistory' component={AsyncHOC(AsyncInspectionHistoryContainer)} />
      <PrivateRoute exact path='/inspectionHistory/:jobId' component={AsyncHOC(AsyncJobContainer)} />
      <PrivateRoute exact path='/surveillance' component={AsyncHOC(AsyncSurveilanceContainer)} />
      <PrivateRoute exact path='/surveillance/:jobId/' component={AsyncHOC(AsyncSurveilanceDetailsContainer)} />
      <PrivateRoute exact path='/surveillance/:jobId/:surveillanceTypeId' component={AsyncHOC(AsyncSurveilanceDetailsContainer)} />
      <PrivateRoute exact path='/atjPrints' component={AsyncHOC(AsyncAtjPrintsContainer)} />
      <PrivateRoute exact path='/devices/:deviceId' component={AsyncHOC(AsyncDeviceContainer)} />
      <PrivateRoute exact path='/devices' component={AsyncHOC(AsyncDevicesContainer)} />
      <PrivateRoute exact path='/inspectionLines' component={AsyncHOC(AsyncInspectionLinesContainer)} />
      <PrivateRoute exact path='/conditionInspectionJobTypes' component={AsyncHOC(AsyncConditionInspectionJobTypesContainer)} />
      <PrivateRoute path='/conditionInspectionJobTypes/:jtId' component={AsyncHOC(AsyncConditionInspectionJobTypeContainer)} />
      <PrivateRoute exact path='/defaultLines' component={AsyncHOC(AsyncDefaultLinesContainer)} />
      <PrivateRoute path='/inspectionLines/:lineId' component={AsyncHOC(AsyncInspectionLineContainer)} />
      <PrivateRoute exact path='/chainManagement/:id' component={AsyncHOC(AsyncChainContainer)} />
      <PrivateRoute exact path='/chainManagement' component={AsyncHOC(AsyncChainsContainer)} />
      <PrivateRoute exact path='/workQueue' component={AsyncHOC(AsyncWorkQueueContainer)} />
      <PrivateRoute exact path='/myInfo' component={AsyncHOC(AsyncMyInfoContainer)} />
      <PrivateRoute path='/inspection/:jobId' render={AsyncHOC(AsyncInspectionContainer)} />
      <Route exact path='/inspection' render={() => <Redirect to='/workQueue' />} />
      <PrivateRoute exact path='/chainCustomers' component={AsyncHOC(AsyncExampleContainer)} />
      <PrivateRoute exact path='/reports' component={AsyncHOC(AsyncReportsContainer)} />
      <PrivateRoute exact path='/copyData' component={AsyncHOC(AsyncCopyDataContainer)} />
      <PrivateRoute exact path='/faultReport' component={AsyncHOC(AsyncFaultReportContainer)} />
      <PrivateRoute exact path='/haynesInfo' component={AsyncHOC(AsyncHaynesInfoContainer)} />
      {/* Redirect to main page: */}
      <Route path='*' render={() => <Redirect to='/' />} />
    </Switch>
  );
}

export default Routes;
