import React, { useEffect } from 'react';
import { Link, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useMsal } from '@azure/msal-react';
import arrowRightImage from '../../webComponents/assets/icons/arrow_right.svg';
import { fetchChains, setSelectedChainId } from '../../reducers/chainReducer';
import { fetchSites, setSelectedSiteId } from '../../reducers/siteReducer';
import { getUser, updateLastSiteId } from '../../reducers/authReducer';
import SmallSelectField from '../common/SmallSelectField';
import MobileTopBar from './MobileTopBar';
import { getUserHasPermissionToView } from '../../lib/userFunctions';
import { Typography } from '@material-ui/core';

function StyledMenu(props) {
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  );
}

const useDesktopStyles = makeStyles((theme) => ({
  mainContainer: {
    height: 50,
    display: 'flex',
    paddingLeft: 20,
    paddingRight: 20,
  },
  breadcrumbContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    flex: 1,
  },
  supportText: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(5),
    fontSize: 14,
  },
  phoneNumber: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  },
  supportLink: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  },
  selectContainer: {
    width: 200,
    '&:nth-child(2)': {
      marginLeft: 10,
    },
  },
  arrowIcon: {
    marginLeft: 3,
  },
  link: {
    color: theme.palette.primary.main,
  },
  userButton: {
    color: '#020700',
    fontSize: 14,
  },
  userImage: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: 'grey',
    marginRight: 8,
  },
  userMenu: {
    fontSize: 16,
    lineHeight: '23px',
    width: 230,
  },
}));

// Route types.
const CHAIN_MANAGEMENT_ROUTE = '/chainManagement';
const CHAIN_ROUTE = '/chain';
const CHAIN_ROUTES = [CHAIN_ROUTE, '/chainProducts', '/sites', '/siteGroups', '/users', '/vehicleGroups', '/jobTypes', '/chainCustomers', '/chainCommonSettings'];

// Permission types.
const CHAIN_PERMISSION = 'chain';

function DesktopTopBar({ routeName, itemName, user, showSiteSelect, handleLogout }) {
  const classes = useDesktopStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const translate = (word) => t(word);

  const chains = useSelector(state => state.chains.chains);
  const selectedChainId = useSelector(state => state.chains.selectedChainId);
  const sites = useSelector(state => state.sites.sites);
  const selectedSiteId = useSelector(state => state.sites.selectedSiteId);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const goToProfile = () => {
    handleClose();
    history.push('/myInfo');
  };

  const logout = () => {
    handleClose();
    handleLogout();
  };

  const isInChainManagementRoute = location.pathname.includes(CHAIN_MANAGEMENT_ROUTE);
  const showChainSelect = !isInChainManagementRoute && getUserHasPermissionToView(user, CHAIN_PERMISSION) && selectedChainId;
  const showUserButton = user && user.id;
  const changeSite = (event) => {
    const siteId = event.target.value;
    dispatch(setSelectedSiteId(siteId));
    dispatch(updateLastSiteId({ lastSiteId: siteId }));
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.breadcrumbContainer}>
        {showChainSelect && (
          <div className={classes.selectContainer}>
            <SmallSelectField
              value={selectedChainId || ''}
              onChange={(event) => dispatch(setSelectedChainId(event.target.value))}
              options={chains}
            />
          </div>
        )}
        {showSiteSelect && (
          <div className={classes.selectContainer}>
            <SmallSelectField
              value={selectedSiteId || ''}
              onChange={(event) => changeSite(event)}
              options={sites}
            />
          </div>
        )}
        {(showChainSelect || showSiteSelect) && (
          <img alt='' src={arrowRightImage} className={classes.arrowIcon} />
        )}
        {location.pathname !== CHAIN_ROUTE ? (
          <Link to={`/${routeName}`} className={classes.link}>{translate(routeName)}</Link>
        ) : (
          <div className={classes.text}>{translate(routeName)}</div>
        )}
        {itemName && (
          <>
            <img alt='' src={arrowRightImage} className={classes.arrowIcon} />
            <div className={classes.text}>{itemName}</div>
          </>
        )}
      </div>
      <div className={classes.infoContainer}>
        <Typography className={classes.supportText}>
          Doris-tuki: <a href="tel:+358937479610" className={classes.phoneNumber}>09 3747 9610</a>
          <a className={classes.supportLink} href="https://idealinspect.atlassian.net/servicedesk/customer/portal/2" target="_blank">Jätä tukipyyntö</a>
        </Typography>
        {showUserButton && (
          <>
            <ButtonBase
              className={classes.userButton}
              onClick={handleClick}
              disableRipple
            >
              <div className={classes.userImage} />
              {user.name}
            </ButtonBase>
            <StyledMenu
              className={classes.userMenu}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={goToProfile} className={classes.userMenu}>
                {t('myInfo')}
              </MenuItem>
              <MenuItem onClick={logout}>
                {t('logout')}
              </MenuItem>
            </StyledMenu>
          </>
        )}
      </div>
    </div>
  );
}

function TopBar() {
  const theme = useTheme();
  const { instance } = useMsal();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const siteRouteMatch = useRouteMatch('/sites/:id');
  const siteGroupRouteMatch = useRouteMatch('/siteGroups/:id');
  const userRouteMatch = useRouteMatch('/users/:id');
  const vehicleGroupMatch = useRouteMatch('/vehicleGroups/:id');
  const jobTypeMatch = useRouteMatch('/jobTypes/:id');
  const inspectionLinesMatch = useRouteMatch('/inspectionLines/:id');
  const devicesMatch = useRouteMatch('/devices/:id');
  const inspectionHistoryMatch = useRouteMatch('/inspectionHistory/:id');
  const surveillanceMatch = useRouteMatch('/surveillance/:id');
  const inspectionMatch = useRouteMatch('/inspection/:id');
  const chainManagementMatch = useRouteMatch('/chainManagement/:id');
  const conditionInspectionJobTypesMatch = useRouteMatch('/conditionInspectionJobTypes/:id');
  const dispatch = useDispatch();
  const selectedChainId = useSelector(state => state.chains.selectedChainId);
  const sites = useSelector(state => state.sites.sites);
  const siteGroups = useSelector(state => state.siteGroups.siteGroups);
  const user = useSelector(state => state.auth.user);
  const token = useSelector(state => state.auth.token);
  const users = useSelector(state => state.users.users);
  const vehicleGroups = useSelector(state => state.vehicleGroups.vehicleGroups);
  const jobTypes = useSelector(state => state.jobTypes.jobTypes);
  const inspectionLines = useSelector(state => state.inspectionLines.inspectionLines);
  const devices = useSelector(state => state.devices.devices);
  const inspectionHistory = useSelector(state => state.inspectionHistory.inspectionHistory);
  const surveillanceHistory = useSelector(state => state.surveillanceHistory.surveillanceHistory);
  const chainList = useSelector(state => state.chains.chainList);
  const conditionInspectionJobTypes = useSelector(state => state.conditionInspectionJobTypes.conditionInspectionJobTypes);
  const { t, i18n } = useTranslation();
  const translate = (word) => t(word);
  const userId = user?.id;

  // Location query parameters.
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const queryChainId = Number(queryParams.get('c'));
  const querySiteId = Number(queryParams.get('s'));

  useEffect(() => {
    if (token) {
      dispatch(fetchChains({ selectedChainId: queryChainId }));
      dispatch(getUser())
        .then(unwrapResult)
        .then((user) => {
          const lang = (user && user.preferredLanguage) || 'fi-FI';
          i18n.changeLanguage(lang);
          // Hack to communicate language change to the webComponents world.
          // Dispatching an action event did not do the trick.
          window.preferredLanguage = lang;
          dayjs.locale(lang === 'sv-FI' ? 'sv' : 'fi');

          // Check that user has permission to queried site.
          if (querySiteId) {
            const isSuperUser = user?.userType === 'superuser';
            const hasSitePermission = isSuperUser || user?.sites?.some((siteIdAsStr) => Number(siteIdAsStr) === querySiteId);
            if (hasSitePermission) {
              dispatch(setSelectedSiteId(querySiteId));
            }
          }
        });
    }
  }, [dispatch, i18n, querySiteId, queryChainId, token]);

  useEffect(() => {
    if (selectedChainId && userId) {
      dispatch(fetchSites({ chainId: selectedChainId }));
    }
  }, [dispatch, selectedChainId, userId]);

  let itemId = '';
  let items = [];
  if (siteRouteMatch) {
    itemId = siteRouteMatch.params.id;
    items = sites;
  } else if (siteGroupRouteMatch) {
    itemId = siteGroupRouteMatch.params.id;
    items = siteGroups;
  } else if (userRouteMatch) {
    itemId = userRouteMatch.params.id;
    items = users;
  } else if (vehicleGroupMatch) {
    itemId = vehicleGroupMatch.params.id;
    items = vehicleGroups;
  } else if (jobTypeMatch) {
    itemId = jobTypeMatch.params.id;
    items = jobTypes;
  } else if (inspectionLinesMatch) {
    itemId = inspectionLinesMatch.params.id;
    items = inspectionLines;
  } else if (devicesMatch) {
    itemId = devicesMatch.params.id;
    items = devices;
  } else if (inspectionHistoryMatch) {
    itemId = inspectionHistoryMatch.params.id;
    items = inspectionHistory;
  } else if (surveillanceMatch) {
    itemId = surveillanceMatch.params.id;
    items = surveillanceHistory;
  } else if (chainManagementMatch) {
    itemId = chainManagementMatch.params.id;
    items = chainList;
  } else if (conditionInspectionJobTypesMatch) {
    itemId = conditionInspectionJobTypesMatch.params.id;
    items = conditionInspectionJobTypes;
  }

  const routeName = itemId
    ? location.pathname.replace(`/${itemId}`, '').replace('/', '')
    : location.pathname.replace('/', '');

  let itemName = '';
  if (itemId) {
    if (itemId === 'new') {
      if (siteRouteMatch) {
        itemName = translate('topBar.newSite');
      } else if (siteGroupRouteMatch) {
        itemName = translate('topBar.newSiteGroup');
      } else if (userRouteMatch) {
        itemName = translate('topBar.newUser');
      } else if (vehicleGroupMatch) {
        itemName = translate('topBar.newVehicleGroup');
      } else if (jobTypeMatch) {
        itemName = translate('topBar.newJobType');
      } else if (devicesMatch) {
        itemName = translate('topBar.newDevice');
      } else if (chainManagementMatch) {
        itemName = translate('topBar.newChain');
      }
    } else {
      const item = items.find(item => item.id.toString() === itemId);
      if (userRouteMatch) {
        itemName = item ? `${item.firstName} ${item.lastName}` : '';
      } else if (inspectionHistoryMatch || surveillanceMatch) {
        itemName = item?.registrationNumber || '';
      } else {
        itemName = item ? item.name : '';
      }
    }
  }

  const isInChainManagementRoute = location.pathname.includes(CHAIN_MANAGEMENT_ROUTE);
  const isInChainRoute = CHAIN_ROUTES.some((route) => location.pathname.includes(route));
  const userHasMultipleSites = sites.length > 1;
  const showSiteSelect = !isInChainManagementRoute && !isInChainRoute && userHasMultipleSites;

  const logOut = () => {
    instance.logoutRedirect({
        postLogoutRedirectUri: process.env.REACT_APP_KAPA_BASE_URL ? `${process.env.REACT_APP_KAPA_BASE_URL}/logout` : '/',
    });
  }

  return isMobileScreen || inspectionMatch ? (
    <MobileTopBar
      routeName={routeName}
      user={user}
      showSiteSelect={showSiteSelect}
      handleLogout={() => logOut()}
    />
  ) : (
    <DesktopTopBar
      routeName={routeName}
      itemName={itemName}
      user={user}
      showSiteSelect={showSiteSelect}
      handleLogout={() => logOut()}
    />
  );
}

export default TopBar;