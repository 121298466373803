import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUsers, sendRequest } from '../api/api';

export const fetchUsers = createAsyncThunk('user/fetchUsers', async ({ chainId, token }, API) => {
  const state = API.getState();
  const users = await getUsers(chainId, state.auth.token);
  return users;
});

export const fetchInspectionUsersInChain = createAsyncThunk('user/fetchInspectionUsersInChain', async ({ chainId }, API) => {
  const queryData = {
    procName: 'GetInspectionUsersInChain',
    data: { chainId },
  };
  const state = API.getState();
  const inspectionUsersInChain = await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return inspectionUsersInChain;
});

export const fetchInspectionUsersInSite = createAsyncThunk('user/fetchInspectionUsers', async ({ chainId, siteId }, API) => {
  const queryData = {
    procName: 'GetInspectionUsersInSite',
    data: { siteId },
  };
  const state = API.getState();
  const inspectionUsers = await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return inspectionUsers;
});

export const fetchUsersInSite = createAsyncThunk('user/fetchUsersInSite', async ({ chainId, siteId }, API) => {
  const state = API.getState();
  const queryData = {
    procName: 'GetUsersInSite',
    data: { chainId, siteId },
  };
  return await sendRequest(chainId, queryData, 'POST', state.auth.token);
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    inspectionUsers: [],
    siteUsers: [],
    isFetching: false,
    fetchError: null,
  },
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      // Sort users by firstName.
      payload.sort((a, b) => a.firstName.localeCompare(b.firstName));
      state.users = payload;
      state.isFetching = false;
      state.fetchError = null;
    },
    [fetchUsers.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.fetchError = payload;
    },
    [fetchInspectionUsersInChain.pending]: (state) => {
      state.inspectionUsersInChain = [];
    },
    [fetchInspectionUsersInChain.fulfilled]: (state, { payload }) => {
      state.inspectionUsersInChain = payload;
    },
    [fetchInspectionUsersInSite.pending]: (state) => {
      state.inspectionUsers = [];
    },
    [fetchInspectionUsersInSite.fulfilled]: (state, { payload }) => {
      state.inspectionUsers = payload;
    },
    [fetchUsersInSite.pending]: (state) => {
      state.siteUsers = [];
    },
    [fetchUsersInSite.fulfilled]: (state, { payload }) => {
      state.siteUsers = payload;
    },
  },
});

export default userSlice.reducer;
