import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendProfileRequest, sendRequest } from '../api/api';

export const getUser = createAsyncThunk('auth/getUser', async (_, API) => {
  const state = API.getState();
  const user = await sendProfileRequest(state.auth.token);
  return user;
});

export const updateLastSiteId = createAsyncThunk('auth/updateLastSiteId', async ({ lastSiteId }, API) => {
  const state = API.getState();
  const userId = state.auth.user.id;
  const chainId = state.chains.selectedChainId;
  const query = {
    procName: 'UpdateLastSiteId',
    data: {
      chainId,
      userId,
      lastSiteId,
    },
  };
  await sendRequest(chainId, query, 'POST', state.auth.token);
  return lastSiteId;
});


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    token: null,
    isFetching: false,
  },
  reducers: {
    setAccessToken(state, { payload }) {
      state.token = payload;
    },
  },
  extraReducers: {
    [getUser.pending]: (state) => {
      state.isFetching = true;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.isFetching = false;
    },
    [getUser.rejected]: (state) => {
      state.isFetching = false;
    },
    [updateLastSiteId.fulfilled]: (state, { payload }) => {
      state.user.lastSiteId = payload;
    },
  },
});

export const {
  setAccessToken,
} = authSlice.actions;

export default authSlice.reducer;
