import './info-section/info-section';
import './sites-section/sites-section';
import { SiteGroupDetailStore } from './siteGroupDetailStore';
import css from './site-groups-detail-root-css';
import arrowLeftImage from '../../assets/icons/arrow-left.svg';
import addWhiteImage from '../../assets/icons/add_white.svg';
import { sendNotificationEvent } from '../../appShell/notificationHelpers';

const BASIC_INFO_FIELDS = ['name', 'description'];
const EMPTY_FORM_DATA = {
  name: '',
  description: '',
  sites: [],
};

// Table columns.
const NAME = 'name';
const ADDRESS = 'address';
const PHONE_NUMBER = 'phoneNumber';
const EMAIL = 'email';
const COLUMN_IDS = [NAME, ADDRESS, PHONE_NUMBER, EMAIL];

class SiteGroupsDetailRoot extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
    this.translationService = { translate: (word) => { return word; } };
    this.store = new SiteGroupDetailStore();
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    // Request services.
    this.requestServices(
      [
        ['translationService', (instance) => this.translationService = instance],
        ['controlState', this.onControlStateChange],
      ],
    );

    // Site data.
    this._state = {
      isBuilt: false,
      siteGroup: EMPTY_FORM_DATA,
      formData: {},
      tabs: new Map(),
    };

    // Build custom element.
    this.build();
  }

  connectedCallback() {
    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
  }

  disconnectedCallback() {
    this.setSiteGroup({});
    // Reset selected tab view.
    const tabViews = this.contentRoot.childNodes;
    tabViews.forEach(view => view.setAttribute('hidden', ''));
    const selectedView = this.contentRoot.querySelector(`div[value=siteGroup-info-section]`);
    selectedView.hidden = false;
    if (!this.root.contains(this.buttonsContainer)) {
      this.root.appendChild(this.buttonsContainer);
    }
  }

  // Requests needed services.
  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  set navData(siteGroup) {
    this.setSiteGroup(siteGroup);
  }

  build = () => {
    this.shadow.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.addModules();
    this.addRoot();
  }

  addRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'sites-detail-root');
    this.root.setAttribute('class', 'main-container');
    window.requestAnimationFrame(() => this.initialRender());
  }

  addModules = () => {
    // Create custom elements.
    for (const tabDef of this.getTabDefinitions()) {
      const element = document.createElement(tabDef.element);
      this._state.tabs.set(tabDef.key, element);
      element.onChange = this.setFormValue;
    }
    this.confirmationDialog = document.createElement('confirmation-dialog');
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
    if (controlState.lastAction === 'chainIdChanged') {
      this.goToListView();
    }
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
  }

  handleDataTableEvent = (event) => {
    if (event.detail.deleteRowData) {
      this.addDeleteSiteConfirmationDialog(event.detail.deleteRowData);
      return;
    }
    const { sortDirection, sortBy, pagination } = event.detail;
    const siteGroupData = this.store.getSiteGroup(this.controlState.chainId, sortBy, sortDirection, pagination.index, pagination.itemsPerPage);
    this._state.tabs.get('siteGroup-sites-section').setSiteGroup(siteGroupData);
  }

  setSiteGroup = async (siteGroup) => {
    const updatedSiteGroupData = siteGroup && siteGroup.id
      ? await this.store.fetchSiteGroup(this.controlState.chainId,siteGroup.id)
      : Object.assign({}, EMPTY_FORM_DATA);
    this._state = {
      ...this._state,
      siteGroup: updatedSiteGroupData,
      formData: Object.assign({}, updatedSiteGroupData),
    };
    window.requestAnimationFrame(() => {
      this.setTitle(updatedSiteGroupData);
      this.refreshTabs();
      this.refreshButtons(updatedSiteGroupData);
      this._state.tabs.get('siteGroup-info-section').siteGroup = updatedSiteGroupData;
      this._state.tabs.get('siteGroup-sites-section').siteGroup = updatedSiteGroupData;
      this._state.tabs.get('siteGroup-sites-section').dataLength = this.store.getSitesLength();
    });
  }

  addTitle = () => {
    const titleContainer = document.createElement('div');
    titleContainer.setAttribute('class', 'title');
    const leftContainer = document.createElement('div');
    titleContainer.appendChild(leftContainer);

    // Name.
    this.nameElement = document.createElement('span');
    this.nameElement.setAttribute('class', 'name-title');
    this.nameElement.appendChild(document.createTextNode(this.translationService.translate('siteGroupView.newSiteGroup')));
    leftContainer.appendChild(this.nameElement);

    // Back to list view.
    this.toListView = document.createElement('div');
    this.toListView.setAttribute('class', 'to-list-view');
    this.toListView.onclick = () => this.goToListView();
    const arrowIcon = document.createElement('img');
    arrowIcon.src = arrowLeftImage;
    this.toListView.appendChild(arrowIcon);
    this.toListView.appendChild(document.createTextNode(this.translationService.translate('siteGroupView.toListView')));
    leftContainer.appendChild(this.toListView);

    this.addSitesButton = document.createElement('button');
    this.addSitesButton.setAttribute('class', 'primary add-button');
    this.addSitesButton.onclick = this.handleAddSites;
    const addIcon = document.createElement('img');
    addIcon.setAttribute('src', addWhiteImage);
    this.addSitesButton.appendChild(addIcon);
    const buttonText = document.createElement('div');
    buttonText.textContent = this.translationService.translate('siteGroupView.addSitesToGroup');
    this.addSitesButton.appendChild(buttonText);
    titleContainer.appendChild(this.addSitesButton);

    this.titleContainer = titleContainer;
    this.root.appendChild(titleContainer);
  }

  setTitle = (siteGroup) => {
    const siteGroupDefined = siteGroup && siteGroup.id;
    const name = siteGroupDefined ? siteGroup.name : this.translationService.translate('siteGroupView.newSiteGroup');
    this.nameElement.removeChild(this.nameElement.firstChild);
    this.nameElement.appendChild(document.createTextNode(name));

    if (siteGroupDefined) {
      this.toListView.removeAttribute('hidden');
      this.addSitesButton.removeAttribute('hidden');
    } else {
      this.toListView.setAttribute('hidden', '');
      this.addSitesButton.setAttribute('hidden', '');
    }
  }

  setFormValue = (property, value) => {
    this._state = {
      ...this._state,
      formData: {
        ...this._state.formData,
        [property]: value,
      },
    };
    this.checkFormButtonStatus();
  }

  refreshTabs = () => {
    while (this.tabs.hasChildNodes()) {
      this.tabs.removeChild(this.tabs.firstChild);
    }
    this.getTabDefinitions().forEach((tabDef, index) => {
      const tab = document.createElement('div');
      if (index === 0) {
        tab.setAttribute('active', '');
      }
      if (tabDef.disabled) {
        tab.setAttribute('disabled', '');
        tab.onclick = null;
      } else {
        tab.onclick = this.setActiveTab;
      }
      tab.setAttribute('class', 'tab');
      tab.setAttribute('value', tabDef.key);
      tab.textContent = this.translationService.translate(`siteGroupView.${tabDef.key.split('-')[1]}`);
      this.tabs.appendChild(tab);
    });
  }

  refreshButtons = (site) => {
    if (site && site.id) {
      this.deleteButton.removeAttribute('hidden');
    } else {
      this.deleteButton.setAttribute('hidden', '');
    }
  }

  addTabs = () => {
    this.tabs = document.createElement('div');
    this.getTabDefinitions().forEach((tabDef, index) => {
      const tab = document.createElement('div');
      if (index === 0) {
        tab.setAttribute('active', '');
      }
      if (tabDef.disabled) {
        tab.setAttribute('disabled', '');
        tab.onclick = null;
      } else {
        tab.onclick = this.setActiveTab;
      }
      tab.setAttribute('class', 'tab');
      tab.setAttribute('value', tabDef.key);
      tab.textContent = this.translationService.translate(`siteGroupView.${tabDef.key.split('-')[1]}`);
      this.tabs.appendChild(tab);
    });
    this.tabs.setAttribute('class', 'tabs');
    this.root.appendChild(this.tabs);
  }

  initialRender = () => {
    this.addTitle();
    this.addTabs();

    const contentRoot = document.createElement('div');
    this.contentRoot = contentRoot;
    this.root.appendChild(contentRoot);

    for (const entry of this._state.tabs.entries()) {
      const wrapper = document.createElement('div');
      wrapper.setAttribute('value', entry[0]);
      if (entry[0] !== 'siteGroup-info-section') {
        wrapper.setAttribute('hidden', '');
      }
      wrapper.appendChild(entry[1]);
      this.contentRoot.appendChild(wrapper);
    }
    this.shadow.appendChild(this.root);

    this.addButtons();
  }

  /**
   * Sets active tab button and update displayed content according to selected tab.
   */
  setActiveTab = (event) => {
    // Reset tab styling and hide all content.
    const tabButtons = this.tabs.childNodes;
    tabButtons.forEach(button => button.removeAttribute('active'));
    const tabViews = this.contentRoot.childNodes;
    tabViews.forEach(view => view.setAttribute('hidden', ''));

    // Set selected tab active.
    event.target.setAttribute('active', '');

    // Show content according to tab value.
    const selectedValue = event.target.getAttribute('value');
    const selectedView = this.contentRoot.querySelector(`div[value=${selectedValue}]`);
    selectedView.hidden = false;

    // Add/remove form buttons.
    if (selectedValue === 'siteGroup-sites-section' && this.root.contains(this.buttonsContainer)) {
      this.root.removeChild(this.buttonsContainer);
      return;
    }
    if (selectedValue !== 'siteGroup-sites-section' && !this.root.contains(this.buttonsContainer)) {
      this.root.appendChild(this.buttonsContainer);
    }
  }

  // Tab definitions.
  getTabDefinitions = () => {
    const siteGroupSelected = this._state.siteGroup && this._state.siteGroup.id;
    return [
      {
        key: 'siteGroup-info-section',
        element: 'site-group-info-section',
      },
      {
        key: 'siteGroup-sites-section',
        element: 'site-group-sites-section',
        disabled: !siteGroupSelected,
      },
    ];
  }

  isDirty = () => BASIC_INFO_FIELDS.some(fid => this._state.siteGroup[fid] !== this._state.formData[fid]);

  goToListView = () => {
    const navEvent = new CustomEvent('siteGroupsNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/site-groups-list',
        data: {},
      },
    });
    this.root.dispatchEvent(navEvent);
  }

  goToSiteGroupDetails = (data) => {
    const navEvent = new CustomEvent('siteGroupsNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/site-groups-detail',
        data,
      },
    });
    this.shadowRoot.dispatchEvent(navEvent);
  }

  saveForm = async () => {
    if (this._state.siteGroup && this._state.siteGroup.id) {
      try {
        const result = await this.store.updateSiteGroup(this.controlState.chainId, this._state.formData);
        this._state.siteGroup = result;
        this.checkFormButtonStatus();
        sendNotificationEvent(this.translationService.translate('siteGroupView.siteGroupSaved'));
      } catch (error) {
        console.log('Failed to save data', error);
        sendNotificationEvent(this.translationService.translate('siteGroupView.saveFailed'), 'error');
      }
      return;
    }
    try {
      const response = await this.store.createSiteGroup(this.controlState.chainId, {
        ...this._state.formData,
        chainId: this.controlState.chainId,
      });
      const id = response[0].id;
      // Update Redux site group data.
      const reduxEvent = new CustomEvent('reduxEvent', {
        bubbles: true,
        composed: true,
        detail: {
          action: 'fetchSiteGroups',
          data: { chainId: this.controlState.chainId },
        },
      });
      window.dispatchEvent(reduxEvent);
      sendNotificationEvent(this.translationService.translate('siteGroupView.newSiteGroupCreated'));
      this.goToSiteGroupDetails({ ...this._state.formData, id });
    } catch (error) {
      console.log('Failed to create site group', error);
      sendNotificationEvent(this.translationService.translate('siteGroupView.saveFailed'), 'error');
    }
  }

  addButtons = () => {
    this.buttonsContainer = document.createElement('div');
    this.buttonsContainer.setAttribute('class', 'buttons-container');
    this.root.appendChild(this.buttonsContainer);

    const leftButtonsContainer = document.createElement('div');
    this.buttonsContainer.appendChild(leftButtonsContainer);

    this.saveButton = document.createElement('button');
    this.saveButton.setAttribute('class', 'primary');
    this.saveButton.textContent = this.translationService.translate('save');
    this.saveButton.setAttribute('disabled', '');
    this.saveButton.onclick = this.saveForm;
    leftButtonsContainer.appendChild(this.saveButton);

    this.cancelButton = document.createElement('button');
    this.cancelButton.textContent = this.translationService.translate('cancel');
    this.cancelButton.onclick = () => {
      if (this.isDirty()) {
        this.addDiscardConfirmationDialog();
      } else {
        this.goToListView();
      }
    };
    leftButtonsContainer.appendChild(this.cancelButton);

    this.deleteButton = document.createElement('button');
    this.deleteButton.textContent = this.translationService.translate('siteGroupView.deleteGroup');
    this.deleteButton.onclick = this.addDeleteConfirmationDialog;
    this.buttonsContainer.appendChild(this.deleteButton);
  }

  handleDiscardChanges = () => {
    this.removeConfirmationDialog();
    this.goToListView();
  }

  addDiscardConfirmationDialog = () => {
    this.addConfirmationDialog(
      this.translationService.translate('leaveConfirmation'),
      this.handleDiscardChanges,
      this.translationService.translate('leavePage'),
    );
  }

  handleDeleteSiteFromGroup = async (siteId) => {
    try {
      await this.store.deleteSiteFromSiteGroup(this.controlState.chainId,siteId);
      this.removeConfirmationDialog();
      sendNotificationEvent(this.translationService.translate('siteGroupView.siteDeletedFromGroup'));
      this.setSiteGroup(this._state.siteGroup);
    } catch (error) {
      console.log('Failed to save data', error);
      sendNotificationEvent(this.translationService.translate('siteGroupView.failedToDeleteSite'), 'error');
    }
  }

  handleDeleteSiteGroup = async () => {
    this.removeConfirmationDialog();

    if (!this._state.siteGroup.id) {
      console.warn('Delete site group, site group:', this._state.siteGroup);
      return;
    }
    try {
      await this.store.deleteSiteGroup(this.controlState.chainId,this._state.siteGroup.id);
      sendNotificationEvent(this.translationService.translate('siteGroupView.siteGroupDeleted'));
      this.goToListView();
    } catch (error) {
      console.log('Failed to delete site group', error);
      sendNotificationEvent(this.translationService.translate('siteGroupView.deleteFailed'), 'error');
    }
  }

  addDeleteSiteConfirmationDialog = (site) => {
    this.addConfirmationDialog(
      `${this.translationService.translate('siteGroupView.deleteSiteFromGroupConfirmation')} '${site.name}'?`,
      () => this.handleDeleteSiteFromGroup(site.id),
      this.translationService.translate('siteGroupView.deleteSiteFromGroup'),
    );
  }

  addConfirmationDialog = (content, onAccept, acceptButtonText) => {
    window.requestAnimationFrame(() => {
      this.root.appendChild(this.confirmationDialog);
      this.confirmationDialog.values = {
        content,
        onAccept,
        acceptButtonText,
        onCancel: this.removeConfirmationDialog,
      };
    });
  }

  addDeleteConfirmationDialog = () => {
    this.addConfirmationDialog(
      `${this.translationService.translate('siteGroupView.deleteConfirmation')} '${this._state.siteGroup.name}'?`,
      this.handleDeleteSiteGroup,
      this.translationService.translate('siteGroupView.deleteGroup'),
    );
  }

  removeConfirmationDialog = () => {
    window.requestAnimationFrame(() => this.root.removeChild(this.confirmationDialog));
  }

  /**
   * Checks if form is modified or contains any errors, and sets button statuses accordingly.
   */
  checkFormButtonStatus = () => {
    const formIsValid = this._state.tabs.get('siteGroup-info-section').isValid;
    if (!formIsValid) {
      this.saveButton.setAttribute('disabled', '');
      this.cancelButton.removeAttribute('disabled');
      return;
    }
    if (this.isDirty()) {
      this.saveButton.removeAttribute('disabled');
      this.cancelButton.removeAttribute('disabled');
    } else {
      this.saveButton.setAttribute('disabled', '');
      this.cancelButton.removeAttribute('disabled');
    }
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      return {
        id: columnId,
        text: this.translationService.translate(`siteGroupView.${columnId}`),
        sortable: true,
      };
    });
  }

  handleAddSites = async () => {
    const freeSites = await this.store.fetchFreeSitesInChain(this.controlState.chainId);
    let selectedSiteIds = [];

    const dialog = document.createElement('data-select-dialog');
    dialog.setAttribute('title', this.translationService.translate('siteGroupView.addSitesTitle'));
    dialog.setAttribute('cancel', this.translationService.translate('siteGroupView.cancel'));
    dialog.setAttribute('ok', this.translationService.translate('siteGroupView.addSelectedSites'));
    dialog.setAttribute('active', 'true');

    const dataTable = document.createElement('data-table');
    dataTable.dataAndLayout = {
      data: freeSites,
      columns: this.getTableColumnDefinitions(),
      sortBy: 'name',
      sortDirection: 'asc',
      paginated: false,
      selectable: true,
      selectedIds: selectedSiteIds,
    };
    dataTable.totalItemCount = freeSites.length;
    dialog.appendChild(dataTable);
    dataTable.addEventListener('dataTableEvent', (event) => {
      event.stopPropagation();
      selectedSiteIds = event.detail.selectedIds;
    });
    dialog.addEventListener('dDataSelect', async (event) => {
      if (event.detail.flag) {
        window.requestAnimationFrame(() => this.shadowRoot.removeChild(dialog));
        try {
          await this.store.updateSelectedSites(this.controlState.chainId,this._state.siteGroup.id, selectedSiteIds);
          const selectedSites = freeSites.filter((site) => selectedSiteIds.includes(site.id));
          const newSites = this._state.siteGroup && this._state.siteGroup.sites
            ? this._state.siteGroup.sites.concat(selectedSites)
            : selectedSites;
          const updatedSiteGroupData = {
            ...this._state.siteGroup,
            sites: newSites,
          };
          this._state.siteGroup.sites = newSites;
          this._state.tabs.get('siteGroup-sites-section').siteGroup = updatedSiteGroupData;
          this._state.tabs.get('siteGroup-sites-section').dataLength = newSites.length;
          sendNotificationEvent(this.translationService.translate('siteGroupView.sitesUpdated'));
        } catch (error) {
          console.log('Failed to add sites', error);
          sendNotificationEvent(this.translationService.translate('siteGroupView.failedToUpdateSites'), 'error');
        }
      } else {
        window.requestAnimationFrame(() => this.shadowRoot.removeChild(dialog));
      }
    })
    this.shadowRoot.appendChild(dialog);
  }
}
window.customElements.define('site-groups-detail-root', SiteGroupsDetailRoot);
