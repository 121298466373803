export const atjResult = {
  interrupted: 1,
  approved: 2,
  rejectedNoBan: 3,
  rejected: 8,
  drivingBan: 9,
};

export const TAB_FAULT_REPORT = 0;
export const TAB_AVERAGES = 1;
export const averageAgeKey = '_averageAge'
export const faultsTotalKey = '_faultsTotal';
export const inspectionsTotalKey = '_inspectedTotal';
export const resultsTotalKey = (r) => '_atjResult' + r;
export const totalKey = '_total';

export const faultGroupKey = {
  total: '_total',
  totalMin: '_totalMin',
  totalMax: '_totalMax',
  trafi: '_trafi',
  doris: '_doris',
  chain: '_chain',
  site: '_site',
};

export const ageGroupKey = (a) => '_ageGroup' + a;

export const allFaultGroupKeys = Object.values(faultGroupKey);

export const rootKey = '_root';
export const separatorKey = '_separator';

// generated by ykl-doris-db/Scripts/parse_trafi_fault_expectations.py
export const trafiExpectationValues = {
  [ageGroupKey(1)]: [
    { level: 10, rejected: 19.75, severity1: 43.39, severity2: 35.00 },
    { level: 9, rejected: 18.70, severity1: 41.01, severity2: 32.85 },
    { level: 8, rejected: 17.64, severity1: 38.62, severity2: 30.71 },
    { level: 7, rejected: 16.59, severity1: 36.24, severity2: 28.56 },
    { level: 6, rejected: 15.54, severity1: 33.85, severity2: 26.42 },
    { level: 5, rejected: 14.49, severity1: 31.47, severity2: 24.27 },
    { level: 4, rejected: 13.43, severity1: 29.08, severity2: 22.13 },
    { level: 3, rejected: 12.38, severity1: 26.70, severity2: 19.98 },
    { level: 2, rejected: 11.33, severity1: 24.31, severity2: 17.84 },
    { level: 1, rejected: 10.27, severity1: 21.93, severity2: 15.70 },
    { level: 0, rejected: 9.22, severity1: 19.54, severity2: 13.55 },
    { level: -1, rejected: 8.17, severity1: 17.15, severity2: 11.41 },
    { level: -2, rejected: 7.11, severity1: 14.77, severity2: 9.26 },
    { level: -3, rejected: 6.06, severity1: 12.38, severity2: 7.12 },
    { level: -4, rejected: 5.01, severity1: 10.00, severity2: 4.97 },
    { level: -5, rejected: 3.96, severity1: 7.61, severity2: 2.83 },
    { level: -6, rejected: 2.90, severity1: 5.23, severity2: 0.68 },
    { level: -7, rejected: 1.85, severity1: 2.84, severity2: -1.47 },
    { level: -8, rejected: 0.80, severity1: 0.46, severity2: -3.61 },
    { level: -9, rejected: -0.26, severity1: -1.93, severity2: -5.76 },
    { level: -10, rejected: -1.31, severity1: -4.31, severity2: -7.90 },
  ],
  [ageGroupKey(2)]: [
    { level: 10, rejected: 34.85, severity1: 81.85, severity2: 61.47 },
    { level: 9, rejected: 33.20, severity1: 77.74, severity2: 58.15 },
    { level: 8, rejected: 31.56, severity1: 73.64, severity2: 54.84 },
    { level: 7, rejected: 29.91, severity1: 69.53, severity2: 51.52 },
    { level: 6, rejected: 28.26, severity1: 65.42, severity2: 48.21 },
    { level: 5, rejected: 26.61, severity1: 61.31, severity2: 44.89 },
    { level: 4, rejected: 24.97, severity1: 57.21, severity2: 41.58 },
    { level: 3, rejected: 23.32, severity1: 53.10, severity2: 38.27 },
    { level: 2, rejected: 21.67, severity1: 48.99, severity2: 34.95 },
    { level: 1, rejected: 20.03, severity1: 44.89, severity2: 31.64 },
    { level: 0, rejected: 18.38, severity1: 40.78, severity2: 28.32 },
    { level: -1, rejected: 16.73, severity1: 36.67, severity2: 25.00 },
    { level: -2, rejected: 15.09, severity1: 32.57, severity2: 21.69 },
    { level: -3, rejected: 13.44, severity1: 28.46, severity2: 18.37 },
    { level: -4, rejected: 11.79, severity1: 24.35, severity2: 15.06 },
    { level: -5, rejected: 10.14, severity1: 20.25, severity2: 11.74 },
    { level: -6, rejected: 8.50, severity1: 16.14, severity2: 8.43 },
    { level: -7, rejected: 6.85, severity1: 12.03, severity2: 5.11 },
    { level: -8, rejected: 5.20, severity1: 7.92, severity2: 1.80 },
    { level: -9, rejected: 3.56, severity1: 3.82, severity2: -1.52 },
    { level: -10, rejected: 1.91, severity1: -0.29, severity2: -4.83 },
  ],
  [ageGroupKey(3)]: [
    { level: 10, rejected: 35.64, severity1: 96.52, severity2: 64.25 },
    { level: 9, rejected: 34.25, severity1: 92.12, severity2: 61.21 },
    { level: 8, rejected: 32.85, severity1: 87.71, severity2: 58.18 },
    { level: 7, rejected: 31.45, severity1: 83.31, severity2: 55.14 },
    { level: 6, rejected: 30.06, severity1: 78.90, severity2: 52.11 },
    { level: 5, rejected: 28.66, severity1: 74.50, severity2: 49.07 },
    { level: 4, rejected: 27.27, severity1: 70.10, severity2: 46.03 },
    { level: 3, rejected: 25.88, severity1: 65.69, severity2: 43.00 },
    { level: 2, rejected: 24.48, severity1: 61.29, severity2: 39.96 },
    { level: 1, rejected: 23.09, severity1: 56.88, severity2: 36.93 },
    { level: 0, rejected: 21.69, severity1: 52.48, severity2: 33.89 },
    { level: -1, rejected: 20.30, severity1: 48.08, severity2: 30.85 },
    { level: -2, rejected: 18.90, severity1: 43.67, severity2: 27.82 },
    { level: -3, rejected: 17.51, severity1: 39.27, severity2: 24.78 },
    { level: -4, rejected: 16.11, severity1: 34.86, severity2: 21.75 },
    { level: -5, rejected: 14.72, severity1: 30.46, severity2: 18.71 },
    { level: -6, rejected: 13.32, severity1: 26.06, severity2: 15.67 },
    { level: -7, rejected: 11.93, severity1: 21.65, severity2: 12.64 },
    { level: -8, rejected: 10.53, severity1: 17.25, severity2: 9.60 },
    { level: -9, rejected: 9.14, severity1: 12.84, severity2: 6.57 },
    { level: -10, rejected: 7.74, severity1: 8.44, severity2: 3.53 },
  ],
  [ageGroupKey(4)]: [
    { level: 10, rejected: 41.81, severity1: 118.16, severity2: 76.86 },
    { level: 9, rejected: 40.29, severity1: 112.98, severity2: 73.57 },
    { level: 8, rejected: 38.77, severity1: 107.80, severity2: 70.28 },
    { level: 7, rejected: 37.25, severity1: 102.62, severity2: 67.00 },
    { level: 6, rejected: 35.73, severity1: 97.44, severity2: 63.71 },
    { level: 5, rejected: 34.21, severity1: 92.25, severity2: 60.42 },
    { level: 4, rejected: 32.68, severity1: 87.07, severity2: 57.13 },
    { level: 3, rejected: 31.16, severity1: 81.89, severity2: 53.84 },
    { level: 2, rejected: 29.64, severity1: 76.71, severity2: 50.56 },
    { level: 1, rejected: 28.12, severity1: 71.53, severity2: 47.27 },
    { level: 0, rejected: 26.60, severity1: 66.35, severity2: 43.98 },
    { level: -1, rejected: 25.08, severity1: 61.17, severity2: 40.69 },
    { level: -2, rejected: 23.56, severity1: 55.99, severity2: 37.40 },
    { level: -3, rejected: 22.04, severity1: 50.81, severity2: 34.12 },
    { level: -4, rejected: 20.52, severity1: 45.63, severity2: 30.83 },
    { level: -5, rejected: 18.99, severity1: 40.45, severity2: 27.54 },
    { level: -6, rejected: 17.47, severity1: 35.26, severity2: 24.25 },
    { level: -7, rejected: 15.95, severity1: 30.08, severity2: 20.96 },
    { level: -8, rejected: 14.43, severity1: 24.90, severity2: 17.68 },
    { level: -9, rejected: 12.91, severity1: 19.72, severity2: 14.39 },
    { level: -10, rejected: 11.39, severity1: 14.54, severity2: 11.10 },
  ],
  [ageGroupKey(5)]: [
    { level: 10, rejected: 55.24, severity1: 166.03, severity2: 129.85 },
    { level: 9, rejected: 53.44, severity1: 158.88, severity2: 124.71 },
    { level: 8, rejected: 51.65, severity1: 151.74, severity2: 119.57 },
    { level: 7, rejected: 49.85, severity1: 144.59, severity2: 114.43 },
    { level: 6, rejected: 48.05, severity1: 137.45, severity2: 109.29 },
    { level: 5, rejected: 46.25, severity1: 130.30, severity2: 104.15 },
    { level: 4, rejected: 44.46, severity1: 123.15, severity2: 99.02 },
    { level: 3, rejected: 42.66, severity1: 116.01, severity2: 93.88 },
    { level: 2, rejected: 40.86, severity1: 108.86, severity2: 88.74 },
    { level: 1, rejected: 39.07, severity1: 101.72, severity2: 83.60 },
    { level: 0, rejected: 37.27, severity1: 94.57, severity2: 78.46 },
    { level: -1, rejected: 35.47, severity1: 87.42, severity2: 73.32 },
    { level: -2, rejected: 33.68, severity1: 80.28, severity2: 68.18 },
    { level: -3, rejected: 31.88, severity1: 73.13, severity2: 63.04 },
    { level: -4, rejected: 30.08, severity1: 65.99, severity2: 57.90 },
    { level: -5, rejected: 28.29, severity1: 58.84, severity2: 52.76 },
    { level: -6, rejected: 26.49, severity1: 51.69, severity2: 47.63 },
    { level: -7, rejected: 24.69, severity1: 44.55, severity2: 42.49 },
    { level: -8, rejected: 22.89, severity1: 37.40, severity2: 37.35 },
    { level: -9, rejected: 21.10, severity1: 30.26, severity2: 32.21 },
    { level: -10, rejected: 19.30, severity1: 23.11, severity2: 27.07 },
  ],
};
