import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { clearNotification } from '../../reducers/notificationReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    '& > div': {
      backgroundColor: theme.palette.green.main,
    },
  },
  error: {
    '& > div': {
      backgroundColor: theme.palette.error.main,
    },
  },
  warning: {
    '& > div': {
      backgroundColor: theme.palette.orange.main,
    },
  },
}));

// Number of milliseconds to wait before automatically closing the snackbar.
const AUTO_HIDE_DURATION = 2000;

// Notification types.
const ERROR = 'error';
const WARNING = 'warning';

function Notification() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const duration = useSelector(state => state.notification.duration ?? AUTO_HIDE_DURATION);
  const message = useSelector(state => state.notification.message);
  const type = useSelector(state => state.notification.type);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearNotification());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={Boolean(message)}
      message={message}
      autoHideDuration={duration < 0 ? null : duration}
      onClose={handleClose}
      classes={{ root: clsx(classes.root, {
        [classes.error]: type === ERROR,
        [classes.warning]: type === WARNING,
      })}}
      action={
        <IconButton size='small' color='inherit' onClick={handleClose}>
          <CloseIcon fontSize='small' />
        </IconButton>
      }
    />
  );
}

export default Notification;
