import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../reducers/authReducer';
import chainReducer from '../reducers/chainReducer';
import siteReducer from '../reducers/siteReducer';
import siteGroupReducer from '../reducers/siteGroupReducer';
import vehicleGroupReducer from '../reducers/vehicleGroupReducer';
import userReducer from '../reducers/userReducer';
import myInfoReducer from '../reducers/myInfoReducer';
import serviceWorkerReducer from '../reducers/serviceWorkerReducer';
import jobTypeReducer from '../reducers/jobTypeReducer';
import inspectionLinesReducer from '../reducers/inspectionLinesReducer';
import conditionInspectionJobTypesReducer from '../reducers/conditionInspectionJobTypesReducer';
import defaultLinesReducer from '../reducers/defaultLinesReducer';
import devicesReducer from '../reducers/devicesReducer';
import measurementTypesReducer from '../reducers/measurementTypesReducer';
import jobReducer from '../reducers/jobReducer';
import haynesReducer from '../reducers/haynesReducer';
import faultReportReducer from '../reducers/faultReportReducer';
import notificationReducer from '../reducers/notificationReducer';
import inspectionHistoryReducer from '../reducers/inspectionHistoryReducer';
import reportsReducer from '../reducers/reportsReducer';
import surveillanceReducer from '../reducers/surveillanceReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  chains: chainReducer,
  sites: siteReducer,
  siteGroups: siteGroupReducer,
  vehicleGroups: vehicleGroupReducer,
  users: userReducer,
  myInfo: myInfoReducer,
  serviceWorker: serviceWorkerReducer,
  jobTypes: jobTypeReducer,
  inspectionLines: inspectionLinesReducer,
  conditionInspectionJobTypes: conditionInspectionJobTypesReducer,
  defaultLines: defaultLinesReducer,
  devices: devicesReducer,
  measurementTypes: measurementTypesReducer,
  jobs: jobReducer,
  haynes: haynesReducer,
  faultReport: faultReportReducer,
  notification: notificationReducer,
  inspectionHistory: inspectionHistoryReducer,
  reports: reportsReducer,
  surveillanceHistory: surveillanceReducer
});

export default rootReducer;