/**
 * @format
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { sendRequest, sendSiteRequest, uploadBlobToAzure } from '../api/api';
import { DEFAULT_SURVEILLANCE_FILTERS } from '../lib/jobConstants';
import { isDefined } from '../lib/jobFunctions';

export const fetchSurveillanceHistory = createAsyncThunk('surveillanceHistory/surveillanceHistory ', async ({ chainId, data }, API) => {
  const query = {
    procName: 'GetSurveillancesInSite',
    data,
  };
  const state = API.getState();
  return sendRequest(chainId, query, 'POST', state.auth.token);
});

export const fetchSiteAnnualSurveillanceSummary = createAsyncThunk('surveillanceHistory/surveillanceSummary ', async ({ chainId, data }, API) => {
  const query = {
    procName: 'GetSiteAnnualSurveillanceSummary',
    data,
  };
  const state = API.getState();
  const surveillanceSummary = await sendRequest(chainId, query, 'POST', state.auth.token);
  let scheduledDocumentAndTechnicalCount = await sendRequest(chainId, {
    procName: 'GetSurveillanceCountBySurveillanceTypeId',
    data: {
      ...data,
      surveillanceTypeId: 5,
    },
  }, 'POST', state.auth.token);

  if (Array.isArray(scheduledDocumentAndTechnicalCount) && scheduledDocumentAndTechnicalCount.length === 0) {
    scheduledDocumentAndTechnicalCount = { actualCount: 0 };
  }

  return surveillanceSummary.map(item => {
    if (item.id === 3 || item.id === 4) {
      return {
        ...item,
        summary: item.summary.map(summaryItem => ({
          ...summaryItem,
          actualCount: summaryItem.actualCount + scheduledDocumentAndTechnicalCount.actualCount ?? 0,
        }))
      };
    }
    return item;
  });
});

export const fetchSurveillanceCountBySurveillanceTypeId = createAsyncThunk('surveillanceHistory/surveillanceCountBySurveillanceTypeId ', async ({ chainId, data }, API) => {
  const query = {
    procName: 'SurveillanceCountBySurveillanceTypeId',
    data,
  };
  const state = API.getState();
  return sendRequest(chainId, query, 'POST', state.auth.token);
});

export const fetchSurveillanceJob = createAsyncThunk('surveillanceHistory/surveillance', async ({ chainId, jobId, surveillanceTypeId }, API) => {
  const query = {
    procName: 'GetSurveillance',
    data: {
      chainId,
      jobId,
      surveillanceTypeId
    },
  };
  const state = API.getState();
  return sendRequest(chainId, query, 'POST', state.auth.token);
});

export const createSurveillanceInspectionJob = createAsyncThunk('surveillanceHistory/createSurveillanceInspectionJob ', async (_, API) => {
  const state = API.getState();
  const job = state.surveillanceHistory.surveillanceJob
  const chainId = state.chains.selectedChainId
  const user = state.auth.user
  const query = {
    procName: 'CreateSurveillanceInspectionJob',
    data: {
      jobId: job.jobId,
      chainId,
      performedByUserId: user.id,
      surveillanceTypeId: parseInt(job.surveillanceTypeId, 10)
    },
  };
  return sendRequest(chainId, query, 'POST', state.auth.token);
});

export const createSurveillanceJob = createAsyncThunk('surveillanceHistory/createSurveillanceJob ', async (_, API) => {
  const state = API.getState();
  const job = state.surveillanceHistory.surveillanceJob;
  const chainId = state.chains.selectedChainId;
  const siteId = state.sites.selectedSiteId;
  const user = state.auth.user;
  const query = {
    procName: 'CreateSurveillance',
    data: {
      jobId: job.jobId,
      chainId,
      siteId,
      surveillanceTypeId: parseInt(job.surveillanceTypeId, 10),
      performedAt: job.performedAt,
      performedByUserId: user.id,
      additionalInformation: job.additionalInformation,
      exceptionInformation: job.exceptionInformation
    },
  };
  return sendRequest(chainId, query, 'POST', state.auth.token);
});

export const updateSurveillanceJob = createAsyncThunk('surveillanceHistory/updateSurveillanceJob ', async (_, API) => {
  const state = API.getState();
  const job = state.surveillanceHistory.surveillanceJob
  const chainId = state.chains.selectedChainId
  const query = {
    procName: 'UpdateSurveillance',
    data: {
      chainId,
      jobId: job.jobId,
      additionalInformation: job.additionalInformation,
      exceptionInformation: job.exceptionInformation,
      acknowledgeAt: isDefined(job.acknowledgeAt) ? dayjs(job.acknowledgeAt).format('YYYY-MM-DD') : null,
      acknowledgedByUserId: isDefined(job.acknowledgeAt) ? job.acknowledgedByUserId : null,
      acknowledgeInformation: isDefined(job.acknowledgeAt) ? job.acknowledgeInformation : null,
      performedAt: job.performedAt,
      surveillanceTypeId: job.surveillanceTypeId
    },
  };
  return sendRequest(chainId, query, 'POST', state.auth.token);
});

export const deleteSurveillanceJob = createAsyncThunk('surveillanceHistory/deleteSurveillanceJob', async ({ chainId, jobId, surveillanceTypeId }, API) => {
  const state = API.getState();
  const query = {
    procName: 'DeleteSurveillance',
    data: {
      chainId,
      jobId: jobId,
      surveillanceTypeId: surveillanceTypeId,
    },
  };
  return sendRequest(chainId, query, 'POST', state.auth.token);
});

export const fetchSurveillanceDocuments = createAsyncThunk('surveillanceHistory/fetchSurveillanceDocuments', async ({ chainId, siteId, surveillanceId }, API) => {
  const state = API.getState();
  const query = {
    procName: 'ListDocuments',
    data: {
      chainId,
      siteId,
      entityType: 'Surveillance',
      entityId: surveillanceId,
    },
  };
  return sendRequest(chainId, query, 'POST', state.auth.token);
});

export const uploadDocument = createAsyncThunk(
  'surveillanceHistory/insertDocument',
  async ({ chainId, siteId, jobId, userId, file, description }, API) => {

    // Fetch SAS token.
    const state = API.getState();
    const fileNameParts = file.name.split('.');
    const extension = '.' + fileNameParts[fileNameParts.length - 1].toLowerCase();
    const tokenData = await sendSiteRequest(chainId, siteId, 'GET', '/blobDocumentToken', `&extension=${extension}&jobId=${jobId}`, null, state.auth.token);

    // Upload file to blob storage.
    uploadBlobToAzure(tokenData.account, tokenData.container, tokenData.sas, tokenData.blobName, file);

    // Save image to DB.
    const queryData = {
      procName: 'InsertDocument',
      data: {
        chainId,
        siteId,
        entityType: 'Surveillance',
        entityId: jobId,
        addedBy: userId,
        guid: tokenData.resourceId,
        name: file.name,
        extension: tokenData.extension,
        description,
        contentLocation: tokenData.resourceUri,
      },
    };
    const result = await sendRequest(chainId, queryData, 'POST', state.auth.token);
    return result;
  });

export const deleteDocument = createAsyncThunk('surveillanceHistory/deleteDocument', async ({ chainId, siteId, jobId, documentId }, API) => {
  const state = API.getState();
  await sendSiteRequest(chainId, siteId, 'DELETE', `/job/${jobId}/document/${documentId}`, '', null, state.auth.token);
  return documentId;
});

const surveillanceSlice = createSlice({
  name: 'surveillanceHistory',
  initialState: {
    surveillanceDocuments: [],
    surveillanceSummary: [],
    surveillanceHistory: [],
    surveillanceJob: {},
    isNewJob: false,
    atjErrorJobs: [],
    filters: DEFAULT_SURVEILLANCE_FILTERS,
    registrationCertificates: [],
    isLoadingSummary: false,
    isLoadingHistory: false,
    isLoading: false,
    selectedPage: 0,
  },
  reducers: {
    setSelectedPage: (state, { payload }) => {
      state.selectedPage = payload;
    },
    setFilters: (state, { payload }) => {
      state.filters = payload;
    },
    initNewSurveillanceFromJob: (state, { payload }) => {
      const { job, user } = payload
      state.surveillanceJob = {
        jobId: job.id,
        surveillanceTypeId: '3',
        performedAt: dayjs().format('YYYY-MM-DD'),
        performedByUserFirstName: user.name,
        additionalInformation: "",
        exceptionInformation: "",
        acknowledgeAt: '',
        acknowledgedByUserFirstName: '',
        acknowledgedByUserLastName: '',
        acknowledgeInformation: '',
        vehicleInformation: {
          make: job.vehicleInformation.make,
          VehicleClass: job.vehicleInformation.vehicleClass,
          VehicleModel: job.vehicleInformation.vehicleModel,
        },
        productInformation: job.productInformation,
        atjResult: job.atjResult,
        registrationNumber: job.registrationNumber,
        VehicleClass: job.VehicleClass,
        jobCompletedAt: job.completedAt,
        assignedUserFirstName: job.assignedUserFirstName,
        assignedUserLastName: job.assignedUserLastName,
        documentInformation: job.documentInformation,
        createdAt: dayjs().format('YYYY-MM-DD'),
        usedSurveillanceTypeIds: job.surveillanceTypeIds,
      }
    },
    updateSurveillanceType: (state, { payload }) => {
      state.surveillanceJob.surveillanceTypeId = payload
    },
    updateAcknowledgement: (state, { payload }) => {
      state.surveillanceJob.acknowledgedByUserFirstName = payload.userFirstName
      state.surveillanceJob.acknowledgedByUserLastName = payload.userLastName
      state.surveillanceJob.acknowledgeAt = payload.date
      state.surveillanceJob.acknowledgedByUserId = payload.userId
      state.surveillanceJob.acknowledgeInformation = payload.comment
    },
    updateTextFields: (state, { payload }) => {
      state.surveillanceJob.additionalInformation = payload.information
      state.surveillanceJob.exceptionInformation = payload.deviations
    },
    updatePerformedAtFields: (state, { payload }) => {
      state.surveillanceJob.performedAt = payload.performedAt
    }
  },
  extraReducers: {
    [fetchSurveillanceDocuments.fulfilled]: (state, { payload }) => {
      state.surveillanceDocuments = payload;
    },
    [fetchSurveillanceDocuments.pending]: (state) => {
      state.surveillanceDocuments = [];
    },
    [fetchSurveillanceHistory.fulfilled]: (state, { payload }) => {
      state.surveillanceHistory = payload.map(job => ({ ...job, id: job.jobId }));  // Id is needed in e.g. breadcrumbs.
      state.isLoadingHistory = false;
    },
    [fetchSurveillanceHistory.pending]: (state, { payload }) => {
      state.isLoadingHistory = true;
    },
    [fetchSiteAnnualSurveillanceSummary.fulfilled]: (state, { payload }) => {
      state.surveillanceSummary = payload;
      state.isLoadingSummary = false;
    },
    [fetchSiteAnnualSurveillanceSummary.pending]: (state, { payload }) => {
      state.isLoadingSummary = true;
    },
    [fetchSurveillanceJob.fulfilled]: (state, { payload }) => {
      state.surveillanceJob = { ...payload };
      state.isLoading = false;
      state.jobState = false;
    },
    [fetchSurveillanceJob.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
  },
});

export const {
  setFilters,
  updateSurveillanceType,
  updateAcknowledgement,
  updateTextFields,
  updatePerformedAtFields,
  initNewSurveillanceFromJob,
  setSelectedPage,
} = surveillanceSlice.actions;


export default surveillanceSlice.reducer;
