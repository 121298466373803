import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as faultConstants from '../../../lib/faultConstants';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#B8B8B8',
    display: 'flex',
    fontSize: 14,
    lineHeight: '20px',
    color: '#091300',
    paddingRight: 8,
    marginBottom: 10,
    '& > div:nth-child(2)': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingTop: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
    },
    background: '#ffffff',
  },
  recommendation: {
    borderColor: theme.palette.fault.recommendation,
  },
  rejected: {
    borderColor: theme.palette.fault.rejected,
  },
  immobilized: {
    borderColor: theme.palette.fault.immobilized,
  },
  containerDisabled: {
    borderColor: 'rgba(209,209,209,0.5)',
    '& > div:not(:nth-child(4))': {
      opacity: 0.4,
    },
  },
  indicator: {
    width: 10,
    backgroundColor: 'rgba(191,191,191,0.2)',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    marginRight: 10,
  },
  greenIndicator: {
    backgroundColor: 'rgba(94,188,0,0.3)',
  },
  orangeIndicator: {
    backgroundColor: theme.palette.fault.rejected,
  },
  redIndicator: {
    backgroundColor: theme.palette.fault.immobilized,
  },
  grayIndicator: {
    backgroundColor: theme.palette.fault.recommendation,
  },
  noIndicator: {
    backgroundColor: 'transparent',
  },
  boldedText: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 600,
    lineHeight: '23px',
  },
  startButton: {
    padding: 8,
  },
  startIcon: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.white.main,
  },
  errorStartIcon: {
    backgroundColor: 'rgba(197,0,0,0.75)',
  },
  hoverEffect: {
    cursor: 'pointer',
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function FaultItem({ fault, onClick, onDelete }) {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const severity = fault.severityLevel;
  const languageId = i18n.language === 'sv-FI' ? 1 : 0;

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <div
      className={clsx(classes.container, {
        [classes.hoverEffect]: !!onClick,
        [classes.recommendation]: severity === faultConstants.SEVERITY_LEVEL_REPAIR_RECOMMENDATION,
        [classes.rejected]: severity === faultConstants.SEVERITY_LEVEL_REJECTED,
        [classes.immobilized]: severity === faultConstants.SEVERITY_LEVEL_VEHICLE_IMMOBILISED,
      })}
      onClick={onClick}
    >
      <div className={clsx(classes.indicator, {
        [classes.redIndicator]: severity === faultConstants.SEVERITY_LEVEL_VEHICLE_IMMOBILISED,
        [classes.orangeIndicator]: severity === faultConstants.SEVERITY_LEVEL_REJECTED,
        [classes.grayIndicator]: severity === faultConstants.SEVERITY_LEVEL_REPAIR_RECOMMENDATION,
      })} />
      <div>
        <div className={classes.boldedText}>
          {faultConstants.getComponentFaultTitle(fault, languageId)}
        </div>
        <div>{faultConstants.getFaultTitle(fault, languageId)}, {faultConstants.getFaultLocationTitle(fault, languageId)}. [{severity}]</div>
      </div>
      {onDelete && (
        <div className={classes.deleteContainer}>
          <IconButton onClick={handleDelete}>
            <CloseIcon className={classes.buttonIcon} />
          </IconButton>
        </div>
      )}
    </div>
  );
}

FaultItem.propTypes = {
  fault: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

export default FaultItem;
