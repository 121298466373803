import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import UserButton from '../common/UserButton';
import MobileNavBar from './MobileNavBar';
import InspectionSideMenu from '../inspection/InspectionSideMenu';
import InspectionStopView from '../inspection/InspectionStopView';
import { isJobDone } from '../../lib/jobFunctions';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: 56,
    backgroundColor: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 7,
    paddingRight: 7,
  },
  mainInspectionContainer: {
    height: 56,
    backgroundColor: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 7,
    paddingRight: 7,
    '& > div:first-child': {
      flex: 1,
    },
  },
  menuIcon: {
    color: theme.palette.black.main,
  },
  title: {
    color: theme.palette.black.main,
    textTransform: 'uppercase',
    flex: 1,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.15,
    lineHeight: '24px',
  },
}));

function MobileTopBar({ routeName, showSiteSelect, user, handleLogout }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const job = useSelector(state => state.jobs.job);

  const [navBarOpen, setNavBarOpen] = useState(false);
  const [inspectionSideMenuOpen, setInspectionSideMenuOpen] = useState(false);
  const [inspectionStopOpen, setInspectionStopOpen] = useState(false);
  const names = user && user.name ? user.name.split(' ') : [];
  const firstName = names.length > 0 ? names[0] : '';
  const lastName = names.length > 1 ? names[1] : '';
  const isInspectionRoute = Boolean(useRouteMatch('/inspection/:id'));

  const translate = (word) => t(word);

  const toggleNavBar = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setNavBarOpen(open);
  };

  const toggleInspectionStop = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setInspectionStopOpen(open);
  };

  const toggleInspectionSideMenu = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setInspectionSideMenuOpen(open);
  };

  if (isInspectionRoute) {
    const jobIsDone = isJobDone(job);

    return (
      <>
        <div className={classes.mainInspectionContainer}>
          <div>
            <IconButton onClick={toggleNavBar(true)} id='show-navbar'>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </div>
          {!jobIsDone && (
            <IconButton onClick={toggleInspectionStop(true)} id='stop-inspection'>
              <CloseRoundedIcon className={classes.menuIcon} />
            </IconButton>
          )}
          <IconButton onClick={toggleInspectionSideMenu(true)} id='show-inspection-menu'>
            <MoreVertRoundedIcon className={classes.menuIcon} />
          </IconButton>
        </div>
        <MobileNavBar
          open={navBarOpen}
          onClose={toggleNavBar(false)}
          onOpen={toggleNavBar(true)}
          showSiteSelect={showSiteSelect}
          handleLogout={handleLogout}
        />
        <InspectionSideMenu
          open={inspectionSideMenuOpen}
          onClose={toggleInspectionSideMenu(false)}
          onOpen={toggleInspectionSideMenu(true)}
        />
        {!jobIsDone && (
          <InspectionStopView
            open={inspectionStopOpen}
            onClose={toggleInspectionStop(false)}
          />
        )}
      </>
    );
  }
  return (
    <>
      <div className={classes.mainContainer}>
        <IconButton onClick={toggleNavBar(true)}>
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        <div className={classes.title}>{translate(routeName)}</div>
        <UserButton
          firstName={firstName}
          lastName={lastName}
          onClick={() => history.push('/myInfo')}
        />
      </div>
      <MobileNavBar
        open={navBarOpen}
        onClose={toggleNavBar(false)}
        onOpen={toggleNavBar(true)}
        showSiteSelect={showSiteSelect}
        handleLogout={handleLogout}
      />
    </>
  );
}

MobileTopBar.propTypes = {
  routeName: PropTypes.string.isRequired,
  showSiteSelect: PropTypes.bool,
  user: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  handleLogout: PropTypes.func.isRequired,
};

MobileTopBar.defaultProps = {
  showSiteSelect: false,
};

export default MobileTopBar;