import './info-section/info-section';
import './sites-section/sites-section';
import './settings-section/settings-section';
import './permissions-section/permissions-section';
import { ChainUserStore } from './chainUserStore';
import { UserDeleteManager } from './deleteUser.js';
import css from './users-detail-root-css';
import arrowLeftImage from '../../assets/icons/arrow-left.svg';

class UsersDetailRoot extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    // Element store & state.
    this.store = new ChainUserStore(this.translationService);
    this._state = {
      user: {},
      activeTab: 'user-info-section',
      activeTabElement: null,
      tabs: new Map(),
      sites: [],
      siteGroups: []
    };

    // Request services.
    this.requestServices(
      [
        ['translationService', (instance) => this.translationService = instance],
        ['controlState', this.onControlStateChange],
      ]
    )

    // Delete manager.
    this.deleteManager = new UserDeleteManager(this.store, () => this._state.user);

    // Build custom element.
    this.build();
  }

  // Requests needed services.
  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if(this.controlState.user.token) {
      this.store.setToken(this.controlState.user.token);
    }
    if (this.controlState.lastAction === 'chainIdChanged') {
      this.goToListView();
    }
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
  }

  setUser = (user) => {
    this._state = {
      ...this._state,
      user,
    };
    this.setTitle();
  }

  set navData(user) {
    this._state = {
      ...this._state,
      user,
    };
    this.setTitle();
  }

  // Life cycle callback,
  // invoked when the custom element is first connected to the document's DOM.
  connectedCallback() {
    // TODO: Is there better way to get the id from the location?
    const hrefParts = window.location.href.split('/');
    const userId = hrefParts[hrefParts.length-1];

    // New user.
    if(userId === 'new') {
      this._state.user = {};
      this._state.activeTab = 'user-info-section';
      this.initTabs();
      this.loadActiveTab();
      return;
    }

    // Fetch user info.
    this.fetchUser(userId).then(() => {
      // Reset active tab.
      this._state.activeTab = 'user-info-section';
      this.initTabs();
      const chainId = this.controlState.chainId;
      Promise.all([
        this.store.sendCrudRequest(chainId, 'GetSitesInChain', { chainId }),
        this.store.sendCrudRequest(chainId, 'GetSiteGroupsInChain', { chainId })])
      .then(data => {
        this._state.sites = data[0];
        this._state.siteGroups = data[1];
        this.loadActiveTab();
      });
    })
  }

  fetchUser = async (userId) => {
    this.setUser(await this.store.getChainUser(this.controlState.chainId, userId));
  }

  build = () => {
    this.shadow.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.addRoot();
  }

  addRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'users-detail-root');
    this.root.setAttribute('class', 'main-container');
    window.requestAnimationFrame(() => this.initialRender());
  }

  addModules() {
    // Create custom elements.
    for (const tabDef of this.getTabDefinitions()) {
      const element = document.createElement(tabDef.element);
      this._state.tabs.set(tabDef.key, element);
    }
  }

  addTitle = () => {
    const titleContainer = document.createElement('div');
    titleContainer.setAttribute('class', 'title');

    // Name.
    this.nameElement = document.createElement('span');
    this.nameElement.setAttribute('class', 'name-title');
    this.nameElement.appendChild(document.createTextNode(this.translationService.translate('usersView.new')));
    titleContainer.appendChild(this.nameElement);

    // Back to list view.
    const toListView = document.createElement('a');
    toListView.setAttribute('class', 'to-list-view');
    toListView.onclick = () => this.goToListView();
    const arrowIcon = document.createElement('img');
    arrowIcon.src = arrowLeftImage;
    toListView.appendChild(arrowIcon);
    toListView.appendChild(document.createTextNode(this.translationService.translate('usersView.toListView')));
    titleContainer.appendChild(toListView);

    this.titleContainer = titleContainer;
    this.root.appendChild(titleContainer);
  }

  isNewUser = () => Object.keys(this._state.user).length === 0;

  setTitle = () => {
    const newUserTitle = this.translationService.translate('usersView.new');
    const name = `${this._state.user.firstName} ${this._state.user.lastName}`;
    this.nameElement.removeChild(this.nameElement.firstChild);
    this.nameElement.appendChild(document.createTextNode(this.isNewUser() ? newUserTitle : name));
  }

  initTabs = () => {
    // Remove and recreate tabs.
    this.tabsRoot.querySelectorAll('*').forEach(n => n.remove());
    this.getTabDefinitions().forEach((tabDef, index) => {
      const tab = document.createElement('div');
      if (tabDef.key === this._state.activeTab) {
        tab.setAttribute('active', '');
      }
      tab.setAttribute('class', 'tab');
      tab.setAttribute('value', tabDef.key);
      tab.textContent = this.translationService.translate(`usersView.${tabDef.key.split('-')[1]}`);

      // Disable other tabs, in case of a new user.
      if (tabDef.disabled) {
        tab.setAttribute('disabled', '');
        tab.onclick = null;
      } else {
        tab.onclick = this.setActiveTab;
      }

      this.tabsRoot.appendChild(tab);
    });
    this.tabsRoot.setAttribute('class', 'tabs');
  }

  addConfirmationDialog = (content, onAccept, acceptButtonText) => {
    window.requestAnimationFrame(() => {
      this.root.appendChild(this.confirmationDialog);
      this.confirmationDialog.values = {
        content,
        onAccept,
        acceptButtonText,
        onCancel: this.removeConfirmationDialog,
      };
    });
  }

  removeConfirmationDialog = () => {
    window.requestAnimationFrame(() => this.root.removeChild(this.confirmationDialog));
  }

  addDiscardConfirmationDialog = (handleDiscardChanges) => {
    this.addConfirmationDialog(
      this.translationService.translate('leaveConfirmation'),
      handleDiscardChanges,
      this.translationService.translate('leavePage'),
    );
  }

  addContentRoot = () => {
    this.contentRoot = document.createElement('div');
    this.contentRoot.setAttribute('id', 'contents');
    this.contentRoot.setAttribute('class', 'contents');
    this.root.appendChild(this.contentRoot);
    this.confirmationDialog = document.createElement('confirmation-dialog');
    this.shadow.appendChild(this.root);
  }

  initialRender = () => {
    this.addTitle();
    this.tabsRoot = document.createElement('div');
    this.root.appendChild(this.tabsRoot);
    this.addContentRoot();
  }

  /**
   * Sets active tab button and update displayed content according to selected tab.
   */
  setActiveTab = (event) => {
    // Reset tab styling and hide all content.
    const tabButtons = this.tabsRoot.childNodes;
    tabButtons.forEach(button => button.removeAttribute('active'));

    // Set selected tab active.
    event.target.setAttribute('active', '');
    this._state.activeTab = event.target.getAttribute('value');
    this.loadActiveTab();
  }

  loadActiveTab = async () => {
    const {activeTab} = this._state;
    // Create and initialize.
    this.deleteManager.setServices(this.controlState, this.translationService);
    this.activeTabElement = document.createElement(activeTab);
    if(this.activeTabElement.setServices)
      this.activeTabElement.setServices(this.controlState, this.translationService);
    if(this.activeTabElement.init)
      this.activeTabElement.init(this.store, this._state.user, this._state.sites, this._state.siteGroups, this.deleteManager, this.addDiscardConfirmationDialog, this.removeConfirmationDialog);

    // Remove all contents and append new child.
    this.contentRoot.querySelectorAll('*').forEach(n => n.remove());
    this.contentRoot.appendChild(this.activeTabElement);
  }

  // Tab definitions.
  getTabDefinitions = () => [
    {
      key: 'user-info-section',
      element: 'user-info-section',
    },
    {
      key: 'user-sites-section',
      element: 'div',  // TODO: change later.
      disabled: this.isNewUser()
    },
    {
      key: 'user-settings-section',
      element: 'div',  // TODO: change later.
      disabled: this.isNewUser()
    },
    {
      key: 'user-permissions-section',
      element: 'div',  // TODO: change later.
      disabled: this.isNewUser()
    },
  ];

  goToListView = () => {
    const navEvent = new CustomEvent('usersNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/users-list',
        data: {}
      },
    });
    this.root.dispatchEvent(navEvent);
  }
}
window.customElements.define('users-detail-root', UsersDetailRoot);