import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import DoneIcon from '@material-ui/icons/DoneRounded';
import RemoveIcon from '@material-ui/icons/RemoveRounded';

import * as jobConstants from '../../lib/jobConstants';
import {
  getPhasesWithStatuses,
  compareCaseInsensitive,
  getProductNames,
  formatDateIsoToLocale,
  isConditionInspectionJob,
} from '../../lib/jobFunctions';

import InspectionImages from './images/InspectionImages';
import InspectionFaults from './faults/InspectionFaults';
import InspectionAtjInfo from './atj/InspectionAtjInfo';
import JobDetails from '../workQueue/JobDetails';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 305,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost, sans-serif',
  },
  header: {
    fontSize: 16,
    borderBottom: `1px solid ${theme.palette.borderGrey.main}`,
    alignItems: 'center',
    padding: 25,
    paddingBottom: 15,
    '& > div:first-child': {
      fontWeight: 500,
      flex: 1,
    },
    '& > div:not(:first-child)': {
      color: '#000000',
      opacity: 0.6,
    },
  },
  button: {
    borderBottom: `1px solid ${theme.palette.borderGrey.main}`,
    width: '100%',
    justifyContent: 'flex-start',
    fontSize: 16,
    height: 48,
    paddingLeft: 25,
    paddingRight: 25,
    textAlign: 'left',
    '& > div': {
      display: 'flex',
      flex: 1,
      '& > div:first-child': {
        fontWeight: 500,
        flex: 1,
      },
      '& > div:nth-child(2)': {
        color: '#000000',
        opacity: 0.6,
      },
    },
  },
  phaseContainer: {
    padding: 10,
  },
  phase: {
    border: `1px solid ${theme.palette.borderGrey.main}`,
    borderRadius: 8,
    height: 64,
    width: '100%',
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    textAlign: 'left',
    '& > div:nth-child(2)': {
      flex: 1,
      '& > div:first-child': {
        fontWeight: 500,
        textTransform: 'uppercase',
      },
      '& > div:last-child': {
        color: '#091300',
      },
    },
  },
  disabledPhase: {
    opacity: 0.5,
  },
  activePhase: {
    borderColor: theme.palette.primary.main,
    backgroundColor: 'rgba(137,62,255,0.1)',
    opacity: 1,
  },
  indicator: {
    height: '100%',
    width: 10,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    marginRight: 10,
  },
  activeIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  statusIcon: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.green.main,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.white.main,
    marginRight: 16,
    marginLeft: 10,
  },
  inProgressStatus: {
    backgroundColor: theme.palette.orange.main,
  },
  disabledStatus: {
    backgroundColor: '#8C8C8C',
  },
}));

// Measurement status values.
const TODO = jobConstants.MEASUREMENT_STATUS[jobConstants.TODO];
const IN_PROGRESS = jobConstants.MEASUREMENT_STATUS[jobConstants.IN_PROGRESS];
const DONE = jobConstants.MEASUREMENT_STATUS[jobConstants.DONE];
const NOT_REQUIRED = jobConstants.MEASUREMENT_STATUS[jobConstants.NOT_REQUIRED];

//#region Button components.
function MenuButton({ title, text, onClick }) {
  const classes = useStyles();
  return (
    <ButtonBase classes={{ root: classes.button }} onClick={onClick}>
      <div>
        <div>{title}</div>
        <div>{text}</div>
      </div>
    </ButtonBase>
  );
}

MenuButton.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

function PhaseButton({ name, status, faultCount, isSelected, onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();

  let statusText = '';
  if (status === TODO) {
    statusText = t('inspectionView.todo');
  } else if (status === IN_PROGRESS) {
    statusText = t('inspectionView.inProgress');
  } else if (status === DONE) {
    statusText = t('inspectionView.done');
  } else if (status === NOT_REQUIRED) {
    statusText = t('inspectionView.notRequired');
  }
  let faultCountText = '';
  if (faultCount > 0) {
    faultCountText = `, ${faultCount} ${t(faultCount > 1 ? 'inspectionView.faultCodePlural' : 'inspectionView.faultCode')}`
  }

  return (
    <ButtonBase
      className={clsx(classes.phase, {
        [classes.disabledPhase]: status === NOT_REQUIRED,
        [classes.activePhase]: isSelected,
      })}
      onClick={onClick}
    >
      <div className={clsx(classes.indicator, { [classes.activeIndicator]: isSelected })} />
      <div>
        <div>{name}</div>
        <div>{statusText}{faultCountText}</div>
      </div>
      {status !== TODO && (
        <div
          className={clsx(classes.statusIcon, {
            [classes.inProgressStatus]: status === IN_PROGRESS,
            [classes.disabledStatus]: status === NOT_REQUIRED,
          })}
        >
          {status === IN_PROGRESS ? <RemoveIcon /> : <DoneIcon />}
        </div>
      )}
    </ButtonBase>
  );
}

PhaseButton.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  faultCount: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
//#endregion

function InspectionSideMenu({ open, onClose, onOpen }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const job = useSelector(state => state.jobs.job);
  const requiredMeasurements = useSelector(state => state.jobs.requiredMeasurements);
  const jobImages = useSelector(state => state.jobs.jobImages);
  const inspectionLines = useSelector(state => state.inspectionLines.allInspectionLines);
  const selectedSite = useSelector(state => state.sites?.sites?.find(s => s.id === state.sites?.selectedSiteId) ?? null);

  const [jobDetailsOpen, setJobDetailsOpen] = useState(false);
  const [atjInfoOpen, setAtjInfoOpen] = useState(false);
  const [imagesOpen, setImagesOpen] = useState(false);
  const [faultsOpen, setFaultsOpen] = useState(false);

  const match = useRouteMatch('/inspection/:jobId/:phaseName');
  if (!match || !job || requiredMeasurements.length === 0) {
    return null;
  }

  const jobId = parseInt(match.params.jobId, 10);
  const phaseName = match.params.phaseName;
  if (isNaN(jobId) || !phaseName) {
    return null;
  }

  const totalFaultCount = (job.faultInformation || []).length;

  const handleJobInfoClick = () => setJobDetailsOpen(true);

  const handleAtjInfoClick = () => setAtjInfoOpen(true);

  const handleImagesClick = () => setImagesOpen(true);

  const handleFaultsClick = () => setFaultsOpen(true);

  const selectPhase = (name) => () => {
    onClose();
    history.push(`/inspection/${jobId}/${name}`);
  };

  const inspectionLine = inspectionLines.find(line => line.id === job.lineId);
  const phases = getPhasesWithStatuses(job, requiredMeasurements, inspectionLine);
  const productNames = getProductNames(job.productInformation) || '-';
  const vin = job.vehicleInformation?.vin || '-';
  const commissioningDay = formatDateIsoToLocale(job.vehicleInformation?.commissioningDay);
  const isConditionInspection = isConditionInspectionJob(job);

  return (
    <SwipeableDrawer
      anchor='right'
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      disableSwipeToOpen={false}
      hysteresis={0.25}
    >
      <div
        className={classes.drawer}
        role='presentation'
        onKeyDown={onClose}
      >
        <div>
          <div className={classes.header}>
            <div>{productNames}</div>
            <div>{vin}</div>
            <div>{t('inspectionView.commissioningDay')}: {commissioningDay}</div>
          </div>
          <MenuButton
            title={t('inspectionView.jobInfo')}
            text={job.registrationNumber}
            onClick={handleJobInfoClick}
          />
          <MenuButton
            title={t(selectedSite?.useHaynesData ? 'inspectionView.atjAndHaynesInfo' : 'inspectionView.atjInfo')}
            text=''
            onClick={handleAtjInfoClick}
          />
          <MenuButton
            title={t('inspectionView.images')}
            text={`${jobImages.length} ${t('inspectionView.pcs')}`}
            onClick={handleImagesClick}
          />
          <MenuButton
            title={t('inspectionView.faults')}
            text={`${totalFaultCount} ${t('inspectionView.pcs')}`}
            onClick={handleFaultsClick}
          />
        </div>
        <div className={classes.phaseContainer}>
          {phases.map((phase) => (
            <PhaseButton
              key={phase.name}
              name={phase.fixed ? t(`measurementTypes.${(isConditionInspection && 'result' === phase.name) ? 'checkingResult' : phase.name}`) : phase.name}
              status={phase.status}
              faultCount={0}
              isSelected={compareCaseInsensitive(phaseName, phase.name)}
              onClick={selectPhase(phase.name)}
            />
          ))}
        </div>
      </div>
      <JobDetails
        open={jobDetailsOpen}
        job={job}
        onClose={() => setJobDetailsOpen(false)}
      />
      <InspectionAtjInfo
        open={atjInfoOpen}
        onClose={() => setAtjInfoOpen(false)}
      />
      <InspectionImages
        open={imagesOpen}
        onClose={() => setImagesOpen(false)}
      />
      <InspectionFaults
        open={faultsOpen}
        onClose={() => setFaultsOpen(false)}
      />
    </SwipeableDrawer>
  );
}

InspectionSideMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
};

export default InspectionSideMenu;